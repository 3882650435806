.importData-container{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 800px;
    max-height: 80%;
    overflow-y: auto;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 30px;
}
.importData-title01{
    display: flex;
    flex-direction: column;
}
.importData-context01{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.importData-context01 img {
    width: 50px;
}
.importData-context02{
    display: flex;
    flex-direction: column;
    text-align: left;

}


.importData-inputbox{
    margin: 50px 0;
    position: relative;
    text-align: left;

}
.importData-inputbox input {
    box-sizing: border-box;
    width: 500px;
    height: 60px;
    background: #ffffff;
    border: 2px solid; /* 두께를 설정합니다. */
    border-image: linear-gradient(to right, red, orange) 20; /* 그라데이션 효과를 설정합니다. */
    padding-left: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #444444;
}
.importData-inputbox img {
    position: absolute;
    left: 16px;
    top: 19px;
    width: 24px;
}
.importData-title{
    border-top: 1px solid black;
}

.importData-title th{
    padding: 20px 0;
}

.importData-Data{
    /*background-color: #e2e2e2;*/
    padding: 14px 0;
    height: 60px;
    font-size: 15px;
    font-weight: 500;
    line-height: 32px;
    color: #101010;
    text-align: center;
    border-top: 1px solid #f2f2f2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.importData-Data td button{
    width: 90px;
    height: 40px;
    cursor: pointer;
}


/*조회 cell 줄 바꿈*/

.data-cell-width{
    max-width: 300px; /* 셀의 최대 너비를 설정 (예: 200px) */
    word-wrap: break-word; /* 단어가 셀 너비를 초과하면 줄 바꿈 */
    word-break: break-word; /* 단어가 길 경우 줄 바꿈 */
    white-space: normal; /* 기본적으로 텍스트를 개행 가능하도록 설정 */
}