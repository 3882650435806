.dmpsend-container {
    margin: 0 auto;
    max-width: 1280px;
}
.dmpsend-banner-img{
     position: relative;
 }
.dmpsend-img02 {
    width: 100%;
    height: 15vh;
}
.banner-text{
    font-weight: 500;
    font-size: 35px;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
}
.banner-text span {
    position: absolute;
    top: 38%;
    left: 40%;
}
.dmp-sendcontainer{
    margin-top: 70px;
}
.dmpsend-description{
    position: absolute;
    width: 80%;
    top: 88%;
    background: linear-gradient(90deg, #ff6005 0%, #ff4426 100%);
}
.dmpsend-description02 {
    display: flex;
    align-items: center;
    padding: 7px;
}
.dmpsend-description02 span {
    color: #ffffff;
}
/*검색어 css*/

.btn-container{
    display: flex;
    justify-content: space-between;
}

.dmpsend-start_line{
    border: 1px solid black;
    margin: 30px 0;
}

.dmpsend-title th{
    padding: 20px 0;
}

.dmpsend-Data{
    padding: 14px 0;
    height: 60px;
    font-size: 15px;
    font-weight: 500;
    line-height: 32px;
    color: #101010;
    text-align: center;
    border-top: 1px solid #f2f2f2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.download-button{
    width: 100px;
    cursor: pointer;
    height: 45px;
    border-radius: 8px;
    border: 2px solid #4e8400;
    color: #4e8400;
    background-color: white;
}
.dmpsend-btn {
    width: 130px;
    text-align: center;
    cursor: pointer;
    height: 45px;
    border-radius: 8px;
    border: 2px solid #ff6005;
    color: #ff6005;
    background-color: white;
}
.download-button:hover,
.dmpsend-btn:hover {
    background-color: #f1f1f1;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.pagination-button {
    padding: 10px 15px;
    margin: 0 5px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 4px;
}

.pagination-button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.pagination-button:hover {
    background-color: #e0e0e0;
}

.pagination-button.active:hover {
    background-color: #0056b3;
}

/*데이터 제출 버튼 css*/

.dmpsend-submit01 {
    width: 130px;
    text-align: center;
    cursor: pointer;
    height: 45px;
    border-radius: 8px;
    border: 2px solid #ff6005;
    color: #ff6005;
    background-color: white;
}
.download-button:hover,
.dmpsend-submit01:hover {
    background-color: #f1f1f1;
}

.dmpsend-submit02{
    width: 130px;
    text-align: center;
    cursor: pointer;
    height: 45px;
    border-radius: 8px;
    border: 2px solid #0f9fe1;
    color: #0f9fe1;
    background-color: white;
}

.dmpsend-submit02:hover, .dmpsend-complete:hover, .dmpsend-complete-opinion:hover {
    background-color: #f1f1f1;
}
.dmpsend-complete{
    width: 130px;
    text-align: center;
    cursor: pointer;
    height: 45px;
    border-radius: 8px;
    border: 2px solid #f36ea1;
    color: #f36ea1;
    background-color: white;
}

.dmpsend-complete-opinion{
    width: 130px;
    text-align: center;
    cursor: pointer;
    height: 45px;
    border-radius: 8px;
    border: 2px solid #7a7a7a;
    color: #7a7a7a;
    background-color: white;
}

/*데이터가 없을때*/
.noDataList{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}