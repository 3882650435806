.dmplist-container{
    margin: 0 auto;
    max-width: 1280px;

}.dmplist-banner-img{
     position: relative;
 }
.dmplist-img02 {
    width: 100%;
    height: 15vh;
}
.banner-text{
    font-weight: 500;
    font-size: 35px;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
}
.banner-text span {
    position: absolute;
    top: 38%;
    left: 40%;
}
.dmplist-description{
    position: absolute;
    width: 80%;
    top: 88%;
    background: linear-gradient(90deg, #ff6005 0%, #ff4426 100%);
}
.dmplist-description02 {
    display: flex;
    align-items: center;
    padding: 7px;
}
.dmplist-description02 span {
    color: #ffffff;
}
/*검색어 css*/

.dmplist-inputbox{
    margin: 100px 0;
    position: relative;
}
.history-select{
    display: flex;
    gap: 20px;
    align-items: center;
}
.history-select select{
    height: 50px;
}
.dmplist-inputbox input {
    box-sizing: border-box;
    width: 500px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    padding-left: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #444444;
}
.dmplist-inputbox img {
    position: absolute;
    left: 16px;
    top: 19px;
    width: 24px;

}
.btn-container{
    display: flex;
    justify-content: space-between;
}
.btn-check{
    display: flex;
    gap: 20px;
}
.btn-check .create02-dmp {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    background-color: #ff5d43;
    text-decoration: none;
    color: white;
    cursor: pointer;

}

.btn-check .send02-dmp {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ff5d43;
    background-color: white;
    cursor: pointer;
}
.btn-check .send02-dmp a {
    text-decoration: none;
    color: #ff5d43;
}

.dmplist-start_line{
    border: 1px solid black;
    margin: 30px 0;
}


.dmplist-title th{
    padding: 20px 0;
}
.dmplist-start_liness{
    margin: 20px 0;
}
.dmplist-Data{
    padding: 14px 0;
    height: 60px;
    font-size: 15px;
    font-weight: 500;
    line-height: 32px;
    color: #101010;
    text-align: center;
    border-top: 1px solid #f2f2f2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.obn01, .obn02, .obn04, .obn05, .obn06{
    width: 10%;
}

.dmplist-selectbox{
    display: flex;
    gap: 12px;
    align-items: center;
}
.annl-number{
    font-size: 20px;
}
#annl-select {
    border-radius: 8px;
    height: 50px;
    font-size: 18px;
}

.tmp01-status {
    color: white;
    padding: 0 3px;
    background-color: red;
    margin-left: 10px;
    font-weight: bold;
    font-size: 12px;
    display: inline-block;
}

/*board 페이지*/

.newimgCur{
    cursor: pointer;
}


.board-input-box {
    display: flex;
    align-items: center;
    margin-top: 30px;
    position: relative;
}
.board-input-box img {
    position: absolute;
    right: 0;
}

/*데이터가 없을때*/
.noDataList{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}