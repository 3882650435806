body,html {

    margin: 0px;
    color: black;
    height: 100%;
}

#root {
    background-color: white;
    display: flex;
    flex-direction: column;
    min-height: 100%; /* 화면 전체 높이를 채움 */
}
.adminpage-container, .dmplist-container, .dmpRealData-banner-container{
    flex: 1;
}
.allproject-container{
    flex: 1;
}

.intro-subName{
    font-weight: bold;
}