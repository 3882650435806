
.dmpObjData-banner-container{
    margin: 0 auto;
    max-width: 1280px;
}
.dmpObjData-banner-img{
    position: relative;
    margin-bottom: 140px;
}
.dmpObjData-img02 {
    width: 100%;
    height: 15vh;
}
.RealDatabanner-text{
    font-weight: 500;
    font-size: 35px;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
}
.RealDatabanner-text span {
    position: absolute;
    top: 38%;
    left: 40%;
}
.dmpRealData-description{
    position: absolute;
    width: 80%;
    top: 88%;
    background: linear-gradient(90deg, #ff6005 0%, #ff4426 100%);
}
.dmpRealData-description02 {
    display: flex;
    align-items: center;
    padding: 7px;
}
.dmpRealData-description02 span {
    color: #ffffff;
}

/*본 데이터 관리 계획 css 코딩*/

.RealData-management-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.under_line{
    border: 1px solid black;
}
.RealData-object-info{
    /*gap: 295px;*/
    display: flex;
    align-items: baseline;
    margin-top: 50px;
}
.RealData-object-info-title {
    display: flex;
    width: 230px;
}
.RealData-object-info-title > .RealData-info {
    font-size: 16px;
}

.RealData-task-title{
    display: flex;
    flex-direction: column;
    gap: 9px;
}
.RealData-task-title .Real-check-obj {
    border: 1px solid #ff6005;
    border-radius: 8px;
    color: #ff6005;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    font-weight: 600;
    white-space: nowrap;
    width: 150px;
    height: 55px;
}
.edit--box {
    background: #f7f7f7;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    margin: 0px 2px 0px 2px;
    padding-left: 10px;
}

input::placeholder {
    font-size: 16px; /* 원하는 크기로 설정 */
    color: #888; /* 선택사항: 색상도 변경할 수 있습니다 */
    text-align: left;
}
.RealData-task-wrapper{
    display: flex;
    gap: 40px;
}
.tast-des{
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: #6c6d70;
}

.required_color{
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #ff4426;
}
.targetData{
    display: flex;
    align-items: baseline;
}
.targetData-title{
    width: 230px;
}
.targetData-value{
    margin-bottom: 20px;
    display: flex;
    gap: 25px;
    height: 54px;
}
.dataSet-bring{
    border: 1px solid #ff6005;
    border-radius: 8px;
    color: #ff6005;
    text-align: center;
    width: 120px;
    /*padding: 20px 40px;*/
    background-color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
    width: 190px;
    height: 55px;
}
.targetData-des{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;

}
.start_line {
    border: 1.2px solid #f6f6f6;
    margin: 30px 0;
}
.RealDataSend-title{
    display: flex;
    align-items: center;
}
.RealData-send {
    width: 210px;
}
.search-input{
    width: 370px;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    background: #f7f7f7;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    padding-left: 20px;
}
.RealData-search-container {
    display: flex;
    gap: 120px;
    align-items: center;
}
.RealData-save-button {
    border: 1px solid #ff6005;
    border-radius: 8px;
    color: #ff6005;
    text-align: center;
    width: 120px;
    /*padding: 20px 40px;*/
    background-color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
    width: 100px;
    height: 55px;
}
.RealData-save-btn-container{
    display: flex;
    justify-content: center;
    gap: 60px;
    margin: 60px;
}
.RealData-save-btn-container button {
    width: 100px;
    height: 60px;
}

/*목록 버튼*/
a {
    text-decoration: none;
    color: black;
}