.footer-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; /* 가운데 정렬 */
    padding: 10px;
    background-color: #333;
    color: #fff;
    z-index: 100;
    position: relative;
}
.footer-img {
    margin: 0 auto;
    max-width: 1280px
}
.footer-left{
    width: 100%;
}
.footer-letter{
    color: black;
    gap: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.footer-letter div {
    line-height: 14px;
    /*margin: 0 auto;*/
    /*max-width: 1280px;*/
}
.footer-contents{
    color: white;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}