.login-container {
    width: 400px;
    margin: 100px auto;
    text-align: center;
}
.login-line{
    border: 1px solid #ff6005;
    margin: 20px 0;
}
.form-group {
    margin-bottom: 30px;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9em;
}

input {
    width: 95%;
    padding: 10px;
    /*margin-bottom: 10px;*/
    font-size: 0.9em;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.password-input {
    display: flex;
    align-items: center;
}
.login-button {
     width: 100%;
     padding: 10px;
     background-color: #ff6005;
     color: #fff;
     border: none;
     border-radius: 4px;
     cursor: pointer;
     font-size: 1em;
 }