.toast-container{
    width: 1030px;
    height: 400px;
    margin: 0 auto;
}
.tui-grid-cell-header{
    background-color: #ffefff !important;
    color: #ff6005 !important;
}

.tui-grid-cell{
    text-align: center !important;
}

.tui-grid-cell:nth-child(4){
    line-height: 1;
    word-spacing: 4px
}
.public-data-addrow{
    background: #ffffff;
    border: 1px solid #ff6005;
    border-radius: 8px;
    color: #ff6005;
    text-align: center;
    margin: 0px 5px 0px 5px;
    padding: 10px 20px;
    cursor: pointer;
}
.row-btn{
    text-align: right;
    margin-bottom: 20px;
}