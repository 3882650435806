.last-dmp-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 뒷배경을 흐리게 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.lastDmp-wrapper{
    background-color: white; /* 모달 배경색을 흰색으로 */
    border-radius: 8px;
    width: 100%;
    max-width: 700px;
    max-height: 80%;
    overflow-y: auto; /* 내용이 넘칠 경우 스크롤 생성 */
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#image-container{
    display: none;
}
.last-dmp-title01{
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.last-dmp-title{
    text-align: center;
    border-top: 1px solid black;
}
.last-dmp-title th {
    width: 10%;
}

.last-dmp-inputbox{
    margin: 50px 0;
    position: relative;
    text-align: left;
}
.last-dmp-inputbox input {
    box-sizing: border-box;
    width: 500px;
    height: 60px;
    background: #ffffff;
    border: 2px solid; /* 두께를 설정합니다. */
    border-image: linear-gradient(to right, red, orange) 20; /* 그라데이션 효과를 설정합니다. */
    padding-left: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #444444;
}
.last-dmp-inputbox img {
    position: absolute;
    left: 16px;
    top: 19px;
    width: 24px;
}

.last-dmp-title th{
    padding: 20px 0;
}

.last-dmp-Data{
    background-color: #e2e2e2;
    padding: 14px 0;
    height: 60px;
    font-size: 15px;
    font-weight: 500;
    line-height: 32px;
    color: #101010;
    text-align: center;
    border-top: 1px solid #f2f2f2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.last-dmp-Data td button{
    width: 110px;
    height: 40px;
    cursor: pointer;
}

/*임시저장 버튼*/

.toggle-button {
    padding: 10px 20px;
    margin: 5px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    cursor: pointer;
    margin-bottom: 24px;
}

.toggle-button.active {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
}
