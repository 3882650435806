
.dmp-banner-container{
    margin: 0 auto;
    max-width: 1280px;
}
.dmp-banner-img{
    position: relative;
    margin-bottom: 140px;
}
.dmp-img02 {
    width: 100%;
    height: 15vh;
}
.banner-text{
    font-weight: 500;
    font-size: 35px;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
}
.banner-text span {
    position: absolute;
    top: 38%;
    left: 40%;
}
.dmp-description{
    position: absolute;
    width: 80%;
    top: 88%;
    background: linear-gradient(90deg, #ff6005 0%, #ff4426 100%);
}
.dmp-description02 {
    display: flex;
    align-items: center;
    padding: 7px;
}
.dmp-description02 span {
    color: #ffffff;
}

/*본 데이터 관리 계획 css 코딩*/

.management-plan-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.under_line{
    border: 1px solid black;
}
.object-info{
    /*gap: 295px;*/
    display: flex;
    align-items: baseline;
    margin-top: 50px;
}
.object-info-title {
    display: flex;
    width: 130px;
    white-space: nowrap;
}
.object-info-title > .info {
    font-size: 16px;
}
.data-set-title > .info {
    font-size: 16px;
}
.task-title{
    display: flex;
    flex-direction: column;
    gap: 9px;
}
.task-title .check-obj {
    border: 1px solid #ff6005;
    border-radius: 8px;
    color: #ff6005;
    text-align: center;
    width: 120px;
    /*padding: 20px 40px;*/
    background-color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
}
.edit--box {
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: inline-flex;
    height: 60px;
    justify-content: center;
    flex-direction: column;
    margin: 0px 2px 0px 2px;
    padding-left: 10px;
}

input::placeholder {
    font-size: 16px; /* 원하는 크기로 설정 */
    color: #888; /* 선택사항: 색상도 변경할 수 있습니다 */
    text-align: left;
}
.task-wrapper{
    display: flex;
    gap: 40px;
}
.objCheck-name{
    display: flex;
}
.task-wrapper .last-dmp {
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    width: 150px;
    height: 60px;
    /*padding: 14px 30px;*/
    border: 1px solid;
    cursor: pointer;
    white-space: nowrap;
}

.task-wrapper .history-dmp {
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    width: 162px;
    height: 60px;
    /*padding: 14px 30px;*/
    border: 1px solid;
    cursor: pointer;
    white-space: nowrap;
}


.last-dmp:disabled {
    /* 비활성화된 상태의 스타일 */
    background-color: #cccccc; /* 회색 배경 */
    color: #666666;            /* 회색 텍스트 */
    cursor: not-allowed;       /* 금지된 커서 */
    opacity: 0.6;              /* 불투명도 낮추기 */
}

.required_color{
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #ff4426;
}
.start_line {
    border: 1.2px solid #f6f6f6;
    margin: 30px 0;
}

/*연구 방법*/
.research-method-info{
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 10px;
}
.research-method-title{
    display: flex;
    flex-direction: column; /* 세로 방향 정렬 */
    align-items: center;    /* 가로 중앙 정렬 */
    justify-content: center; /* 세로 중앙 정렬 */
    text-align: center;      /* 텍스트 가운데 정렬 */
    height: 100%;            /* 부모 요소 높이 기준 */
}

.research-method-title textarea{
    background-color: #fafafafa;
    width: 1010px;
    height: 300px;
}
/*데이터세트 양식 추가*/
.data-set-container{
    text-align: center;
}
.data-set-add{
    font-size: 24px;
    padding: 14px 120px;
    background: #ffffff;
    border: 1px solid #ff6005;
    border-radius: 8px;
    color: #ff6005;
    text-align: center;
    margin: 0px 5px 0px 5px;
    cursor: pointer;
}
/*연구데이터 구성*/
.configuring-research-data-info{
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 10px;
}
/*연구데이터 분석 계획*/

.data-analysis-info{
    display: flex;
    align-items: start;
    /*gap: 195px;*/
    flex-direction: column;
    gap: 10px;
}
/*연구데이터 공개 코드*/
.data-public-info, .data-management-info{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    /*white-space: nowrap;*/
}
.data-hispublic-info, .data-hisTitle-info{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.contents-section{
    width: 1062px;
    padding-left: 210px;
}

/*데이터 세트 삭제*/
.data-set-title-wrapper{
    display: flex;
    align-items: start;
    gap: 20px;
    /*margin-bottom: 30px;*/
}

.toastui-wrapper{
    margin-bottom: 40px;
    /*margin-left: 200px;*/
}
.data-set-title{
    display: flex;
    align-items: center;
    white-space: nowrap;
    /*width: 230px;*/
}
.test-summary{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.data-set-delete{
    display: flex;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #ff6005;
    border-radius: 8px;
    color: #ff6005;
    text-align: center;
    margin: 0px 5px 0px 5px;
    padding: 10px 20px;
    cursor: pointer;
    gap: 7px;
}
#selectBox {
    height: 50px;
    width: 210px;
    font-size: 14px;
}
.selectbox-container{
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.selectbox-check{
    display: flex;
    gap: 20px; /* 요소들 사이의 간격을 설정 */
    margin-bottom: 30px;
}
#data-type-name {
    width: 200px;
    height: 32px;
}
#data-type-desc{
    width: 300px;
    height: 32px;
}
.resultData{
    height: 48px;
}
.save-container{
    display: flex;
    justify-content: center;
    gap: 70px;
    margin: 35px;
}
.save-container button {
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 600;
}
#save-btn {
    color: white;
    background-color: #ff5d43;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    cursor: pointer;
}
#temporary-Storage, #dmp_list, #his-btn {
    color: black;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    cursor: pointer;
}
#admin_list{
    color: black;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    cursor: pointer;
}

/*목록 버튼*/
a {
    text-decoration: none;
    color: black;
}

/*side bar css*/

.dmp-side-wrapper{
    display: flex;
    width: 100%;
}

/* 전체 컨테이너 */
.side-container {
    position: fixed;
    margin-right: 20px;
    height: 100vh;
    /*margin-top: 8px;*/
}

.side-sidebar{
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    text-align: center;
    background-color: #f1715d;
    color: white;
    border-radius: 10px;
    /*overflow-x: auto; !* X축 스크롤 *!*/
    /*overflow-y: auto; !* Y축 스크롤 *!*/
    width: 185px;
    /*height: 100%;*/
}
.side-name{
    padding: 10px;
    color: black;
    font-weight: bold;
    font-size: 18px;
}
.start-side_line{
    border: 1.2px solid black;
}

.side-hisBtn-container{
    margin-bottom: 4px;
}
.side-hisBtn{
    color: black;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    cursor: pointer;
}

.side-menu-list {
    max-height: 400px; /* 최대 높이 설정 */
    overflow-y: auto; /* Y축 스크롤 활성화 */
    list-style-type: none;
    padding: 0 14px;
    text-align: left;
}

/* 스크롤바 스타일 */
.side-menu-list::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
}

.side-menu-list::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.6); /* 스크롤바 색상 */
    border-radius: 10px; /* 둥글게 */
}

.side-menu-list::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.8); /* 호버 시 색상 */
}

.side-menu-list::-webkit-scrollbar-track {
    background-color: transparent; /* 스크롤 트랙 배경 */
}

.side-menu-button {
    background: none;
    border: none;
    font-weight: 600;
    color: white;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
}
.side-datasets-button{
    text-align: left;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 4px;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}
#imgDiagram{
    width: 100%;
}
#imgPlaceholder{
    width: 340px;
}

.menu-button:hover {
    text-decoration: underline;
}

.production-plan{
    margin-top: 30px;
}