/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Container */
.modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Modal Title */
.modal h2 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
}

/* File Input */
.file-input {
    margin: 10px 0;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

/* File Info */
.file-info {
    margin: 10px 0;
    text-align: left;
    font-size: 14px;
    color: #555;
}

/* Buttons */
.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.files-upload-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.upload-button:hover {
    background-color: #45a049;
}

.close-button {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.close-button:hover {
    background-color: #e53935;
}
