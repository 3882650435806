.adminpage-container{
    margin: 0;
    padding: 0;
}
.menu-list {
    box-sizing: border-box; /* 패딩을 너비에 포함 */
    display: flex;
    gap: 40px;
    margin-top: 35px;
    padding: 0 40px;
}
.menu-title{
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-weight: bold;
    cursor: pointer;
}
.admin-under{
    border-bottom: 1px solid darkgrey;

}
.admin-line{
    border-right: 2px solid darkgrey;
}
/*.menu-title:hover {*/
/*    color: #ff6005;*/
/*}*/

.menu-item {
    white-space: nowrap;
    margin-bottom: 10px; /* 항목 간 간격 */
    font-size: 16px; /* 글자 크기 */
    display: flex; /* 플렉스 박스 */
    justify-content: space-between; /* 양 끝 정렬 */
    align-items: center; /* 세로 중앙 정렬 */
    gap: 20px;
}

.arrow {
    font-size: 12px; /* 화살표 크기 */
}
.check-calendar{
    display: flex;
    flex-direction: column;
    width: 100%;
    /*height: 1140px;*/
    height: 100vh;
    gap: 15px;

}
.adminlist-img02{
    width: 100%;
    height: 24vh;
    display: block;
}

.adminhd-line{
    border: 4px solid #ff6005;
}
.small-title{
    position: relative;
}
.menu-name{
    position: absolute;
    right: 120px;
    bottom: 0;
    color: white;
}


.custom-tooltip {
    z-index: 9999 !important; /* Tooltip이 맨 앞에 오도록 z-index 설정 */
}

.rbc-calendar {
    width: 100%;
    height: 100%;
}


/* 각 시간 슬롯의 높이 설정 */
.rbc-time-slot {
    min-height: 40px;
}

/* 스크롤 조정 */
.rbc-time-content {
    overflow-x: auto;
    overflow-y: auto;
}


.rbc-day-slot .rbc-events-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 이벤트가 왼쪽에서 시작 */
}

.rbc-event {
    width: 100%;
    margin-bottom: 5px;
    box-sizing: border-box;
}

.openStat-btn{
    display: flex;
    justify-content: end;
    margin-top: 30px;
}

.cal-middle-line{
    border-bottom: 2px solid darkgrey;
    margin: 10px 0;
}
.rbc-button-link{
    font-size: 20px;
}

.admin-searchbox{
    margin: 20px 0;
    position: relative;
}
.admin-searchbox input {
    box-sizing: border-box;
    width: 500px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    padding-left: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #444444;
}
.admin-searchbox img {
    position: absolute;
    left: 16px;
    top: 19px;
    width: 24px;

}

/*+ more 창*/

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 14px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 80%;  /* 화면의 80% 너비 */
    max-width: 700px;  /* 최대 너비를 1200px로 제한 */
    height: auto;
    max-height: 100vh;  /* 최대 높이를 화면의 80%로 제한 */
    overflow-y: auto;  /* 내용이 넘칠 경우 스크롤을 추가 */
}

.calendar-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f7f7f7;
    border-radius: 14px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    /*max-width: 700px;  !* 최대 너비를 1200px로 제한 *!*/
    height: auto;
    max-height: 100vh;  /* 최대 높이를 화면의 80%로 제한 */
    overflow-y: auto;  /* 내용이 넘칠 경우 스크롤을 추가 */
}

.modal-content {
    position: relative; /* 닫기 버튼을 절대 위치로 배치하기 위해 상대적 위치 지정 */
}

.modal-content h3 {
    margin-top: 0;
    text-align: center;
}

.modal-content ul {
    list-style-type: none;
    padding: 0;
}

.modal-content li {
    margin: 5px 0;
}

.modal-content button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* 닫기 버튼을 오른쪽 상단에 위치 */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: #007bff;
    cursor: pointer;
}

