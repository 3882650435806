.application-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 뒷배경을 흐리게 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}


.form-container {
    max-width: 940px;
    width: 90%; /* 작은 화면에서 너비를 유연하게 조정 */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: white;
    overflow-y: auto; /* 내용이 넘칠 경우 세로 스크롤 생성 */
    overflow-x: auto; /* 가로 내용이 넘칠 경우 가로 스크롤 생성 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    max-height: 90vh; /* 화면이 작아질 때 높이를 제한하여 스크롤 생성 */
}



/* 제목 스타일 */
h2 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* 예시 applicantInfo 스타일 */
#applicantInfo {
    width: 15px;
    color: black;
}
#applicationDetails {
    width: 15px;
}
.section {
    margin: 20px;
}

.checkbox {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.applicant-info-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
}

.applicant-info-table td {
    border: 1px solid black;
    padding: 10px;
    text-align: center;
}

.applicant-info-table .main-title {
    font-weight: bold;
    vertical-align: middle;
    width: 100px;
    background-color: #efefef;
}

.applicant-info-table input[type="text"] {
    width: 98%;
    padding: 5px;
    margin: 0px;
    box-sizing: border-box;
}

.notice {
    font-size: 12px;
    margin-top: 10px;
}

.data-request-table th, .data-request-table td {
    text-align: center;
}

.data-usage-plan {
    margin-top: 10px;
}

.data-usage-plan textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    resize: vertical;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer p {
    margin-right: 20px;
}

.signature {
    display: flex;
    gap: 28px;
    align-items: baseline;
    width: 40%;
}

.signature span {
    white-space: nowrap;
}

.application-btn{
    display: flex;
    justify-content: center;
    gap: 10px;
}

.application-apply{
    border: 1px solid #ff6005;
    border-radius: 8px;
    background-color: #ff6005;
    color: white;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
    width: 100px;
    height: 55px;
}

.application-cancel{
    border: 1px solid #ff6005;
    border-radius: 8px;
    background-color: white;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
    width: 100px;
    height: 55px;
}

/*서약서 ui*/

.Openoath-title{
    display: flex;
    align-items: center;
}
.Openoath-send {
    width: 120px;
}

/* 신청서 보기 페이지  */
.mypage-viewData{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}
.application-sendFile-container{
    display: flex;
    align-items: center;
    gap: 24px;
}

.application-sendFile-container input {
    display: none;
}

.application-underlien{
    border: 1px solid #474747;;
    margin: 20px 0;
}
.application-title02-cont{
    white-space: nowrap;
}
.application-title02{
    display: flex;
    align-items: center;
    gap: 60px;
}

.purpose-title{
    display: flex;
    gap: 20px;
}
.period-days{
    display: flex;
    gap: 28px;
    align-items: baseline;
}

.text-table{
    display: flex;
    gap: 20px;
    align-items: center;
}
.text-titl02{
    white-space: nowrap;
}
.textarea-wrapper{
    width: 100%;
}
.period-dayes{
    display: flex;
    align-items: center;
    gap: 30px;
}
.periodView-dayes{
    display: flex;
    align-items: center;
    gap: 11px;
}

.application-file-list{
    display: flex;
    flex-direction: column;
    gap: 14px;
}