.year-name{
    width: 100%;
    /*height: 100vh;*/
}
.adminpage-container{
    /*height: 100vh;*/
}
.submin-container{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
}
.submin-year{
    height: 40px;
}
.year-selectbox{
    display: flex;
    align-items: center;
    gap: 10px;
}

/*데이터 제출현황*/

.submit-result{
    display: flex;
    flex-direction: column;
}

/*일괄 변경*/
.dataStatus-allChange{
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    background-color: #ff5d43;
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.dataStatus-management{
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    background-color: #ff5d43;
    text-decoration: none;
    color: white;
    cursor: pointer;
    margin-left: 10px;
}

.dataStatus-allChange02{
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    background-color: lightskyblue;
    text-decoration: none;
    color: white;
    cursor: pointer;
}
.application-status{
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ff5d43;
    background-color: #ffffff;
    text-decoration: none;
    color: black;
    cursor: pointer;
}
.application-status-container{
    display: flex;
    gap: 20px;
}

.status-btn04{
    display: flex;
    gap: 30px;
    justify-content: flex-end;
}

/*접근 권한 별*/
.accessCheck-year{
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 7px;
}
.menu-item > a {
    cursor: pointer !important;
}

/*유저별 권한 라디오*/
.auth-radio{
    width: 24px;
}
.auth-container{
    display: flex;
    gap: 20px;
    width: 100%;
}
.menu-list {
    box-sizing: border-box; /* 패딩을 너비에 포함 */
    display: flex;
    gap: 40px;
    margin-top: 35px;
    padding: 0 40px;
}
.auth-changebtn{
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    background-color: #ff5d43;
    text-decoration: none;
    color: white;
    cursor: pointer;
    white-space: nowrap;
}

/*신청서 상세 페이지*/
.application-mngDetail{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

/*생산 계획 누락 css*/

.prod-table {
    width: 100%;
    border-collapse: collapse;
    margin : 30px 0;
}

.prod-table th, .prod-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.prod-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.middle-line{
    border-bottom: 2px solid darkgrey;
}

.title-line02{
    border-bottom: 2px solid darkgrey;
    margin: 20px 0;
}

.title-line03,
.title-line04,
.title-line05 {
    border-bottom: 2px solid darkgrey !important;
}


.auth-user-cell {
    max-width: 300px; /* 셀의 최대 너비를 설정 (예: 200px) */
    word-wrap: break-word; /* 단어가 셀 너비를 초과하면 줄 바꿈 */
    word-break: break-word; /* 단어가 길 경우 줄 바꿈 */
    white-space: normal; /* 기본적으로 텍스트를 개행 가능하도록 설정 */
}

.blue-button {
    background-color: #87CEEB;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    text-decoration: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
}

.kfri-button {
    background-color: #ff5d43;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    text-decoration: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
}
.red-text {
    color: red;
    font-weight: bold;
    font-size: 14px;
}

.black-text {
    color: #65bde0;
    font-weight: bold;
    font-size: 14px;
}
.menu-item.active a {
    color: #ff5d43 !important;
    font-weight: bold;
}


.react-datepicker-wrapper input {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;

    width: 95px;
    text-align: center;
    box-sizing: border-box;
}

.datepicker-input:focus {
    border-color: #007bff; /* 포커스 시 파란색 테두리 */
    outline: none;
}
/*인쇄 페이지 css*/

.Print-Container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.Print-Container button{
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ff5d43;
    background-color: #ffffff;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.admin-create{
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ff5d43;
    background-color: #ffffff;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

/*과제 업로드*/
.open-modal-button{
    background-color: #87CEEB;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    text-decoration: none;
    color: white;
    cursor: pointer;
    margin-left: 10px;
}

.proj-title{
    display: flex;
    gap: 10px;
}

.fileDown-container{
    display: flex;
    gap: 4px;
    align-items: baseline;
}
.download-button:disabled {
    background-color: #d3d3d3; /* 비활성화 상태 색상 */
    border: 1px solid #d3d3d3;
    color: #1e1d1d;
    cursor: not-allowed; /* 비활성화 시 마우스 커서 변경 */
    opacity: 0.6; /* 약간 투명하게 */
}

/*no 데이터*/
.noDataCss{
    font-size: 18px; /* 글자 크기 설정 */
    font-weight: bold;
    margin-bottom: 20px;
}