.container{
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 16px 0;
    background-color: white;
    max-width: 1280px;
    margin: 0 auto;
}
.header-icon01{
    width: 34px;
}
.header-icon02{
    width: 38px;
}
.navbar{
    display: flex;
    gap: 80px;
    height: 33px;
}
.navbar a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}
.login-success{
    display: flex;
    gap: 10px;
    align-items: center;
}

.login button{
    padding: 8px;
}

.dropdown {
    position: relative;
}

/* 드롭다운 메뉴 숨김 */
.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%; /* 부모 요소 바로 아래에 위치 */
    left: 50%; /* 부모 요소의 가운데에 위치 */
    transform: translateX(-50%); /* 가로 방향으로 중앙 정렬 */
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    min-width: 120px;
    text-align: center;
}

/* 드롭다운 메뉴에 링크 스타일 */
.dropdown-menu a {
    display: block;
    padding: 20px 16px; /* 항목 사이의 간격을 더 넓힘 */
    text-decoration: none;
    color: black;
}

.dropdown-menu a:hover {
    background-color: #ddd;
}

/* 마우스를 올리면 드롭다운 메뉴를 표시 */
.dropdown:hover .dropdown-menu {
    display: block;
}