
.dmpRealData-banner-container{
    margin: 0 auto;
    max-width: 1280px;
}
.dmpRealData-banner-img{
    position: relative;
    margin-bottom: 140px;
}
.createCallDMP{
    display: flex;
}
.dmpRealData-img02 {
    width: 100%;
    height: 15vh;
}
.RealDatabanner-text{
    font-weight: 500;
    font-size: 35px;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
}
.RealDatabanner-text span {
    position: absolute;
    top: 38%;
    left: 40%;
}
.dmpRealData-description{
    position: absolute;
    width: 80%;
    top: 88%;
    background: linear-gradient(90deg, #ff6005 0%, #ff4426 100%);
}
.dmpRealData-description02 {
    display: flex;
    align-items: center;
    padding: 7px;
}
.dmpRealData-description02 span {
    color: #ffffff;
}

/*본 데이터 관리 계획 css 코딩*/

.RealData-management-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.under_line{
    border: 1px solid black;
}
.RealData-object-info{
    /*gap: 295px;*/
    display: flex;
    align-items: baseline;
    margin-top: 50px;
}
.RealData-object-info-title {
    display: flex;
    width: 230px;
}
.RealData-object-info-title > .RealData-info {
    font-size: 16px;
}

.RealData-task-title{
    display: flex;
    flex-direction: column;
    gap: 9px;
}
.RealData-task-title .Real-check-obj {
    border: 1px solid #ff6005;
    border-radius: 8px;
    color: #ff6005;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    font-weight: 600;
    white-space: nowrap;
    width: 150px;
    height: 55px;
}
.edit--box {
    background: #f7f7f7;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    margin: 0px 2px 0px 2px;
    padding-left: 10px;
}

input::placeholder {
    font-size: 16px; /* 원하는 크기로 설정 */
    color: #888; /* 선택사항: 색상도 변경할 수 있습니다 */
    text-align: left;
}
.RealData-task-wrapper{
    display: flex;
    gap: 40px;
}
.tast-des{
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: #6c6d70;
}

.required_color{
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #ff4426;
}
.dataCreate-container{
    display: flex;
    gap: 100px;
}
.dataCreate-title{
    display: flex;
    white-space: nowrap;
}
.dataSets-Inputboxs{
    display: flex;
    gap: 25px;
}
.dataSets-callContainer{
    display: flex;
    gap: 20px;
}
.dmpDataSets-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
}
.createDataPage{
    height: 55px;
    width: 100px;
    background: #f7f7f7;
    border: 1px solid #f2f2f2;
}
.DataPage-selectbox-check{
    display: flex;
    gap: 20px;
}
.dataSet-bring{
    border: 1px solid #ff6005;
    border-radius: 8px;
    color: #ff6005;
    text-align: center;
    width: 120px;
    /*padding: 20px 40px;*/
    background-color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
    width: 190px;
    height: 55px;
}
.datePage-targetData-des{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.start_line {
    border: 1.2px solid #f6f6f6;
    margin: 30px 0;
}
.RealDataSend-title{
    display: flex;
    align-items: center;
}
.RealData-send {
    width: 210px;
}
.search-input{
    width: 370px;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    background: #f7f7f7;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    padding-left: 20px;
}
.RealData-sendFile-container {
    display: flex;
    /*gap: 20px;*/
    /*align-items: center;*/
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}
.RealData-save-button {
    border: 1px solid #ff6005;
    border-radius: 8px;
    color: #ff6005;
    text-align: center;
    width: 120px;
    /*padding: 20px 40px;*/
    background-color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
    width: 100px;
    height: 55px;
}
.RealData-save-btn-container{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 60px;
}
.RealData-save-btn-container button {
    width: 100px;
    height: 60px;
}

/*목록 버튼*/
a {
    text-decoration: none;
    color: black;
}

.RealData-sendFile-container input {
    display: none;
}
.fileName{
    border: 1px solid;
    padding: 0 25px;
    border-radius: 8px;
    display: flex;
    position: relative;
}
.fileName img{
    position: absolute;
    width: 30px;
    right: 0;
    top: 0;
    z-index: 100;
}
.RealData-bring{
    border: 1px solid #ff6005;
    border-radius: 8px;
    background-color: #ff6005;
    color: white;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
    width: 100px;
    height: 55px;
}
.RealData-btnContainer{
    display: flex;
    gap: 20px;
}
.public-dataContainer{
    display: flex;
    gap: 125px;
    margin-top: 40px;
}
.label-btn{
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
}
.label-btn input {
    width: initial;
}
.label-name label{
    margin-bottom: 10px;
}

.creatFile-upload{
    display: flex;
    gap: 20px;
}

.view-file02{
    display: flex;
    gap: 20px;
}

.RealData-view-container input {
    display: none;
}
.RealData-view-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/*수정 */
.modify-sendFile-container input {
    display: none;
}
.modify-sendFile-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
}

.modify-sendFile-container-preview input {
    display: none;
}
.modify-sendFile-container-preview{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
}

.preview-img{
    display: flex;
    gap: 30px;
}


/*board 상세 페이지 css*/

.boadr-detail{
    display: flex;
    align-items: center;
    gap: 25px;
}

.boadr-detail input {
    display: none;
}
.board-category{
    display: flex;
    gap: 70px;
    margin-top: 50px;
}
.category-select{
    width: 700px;
    height: 50px;
    font-size: 14px;
}

.board-write-title{
    display: flex;
    gap: 100px;
    margin-top: 50px;
}
.boardWrite-sendFile-container{
    display: flex;
    gap: 25px;
}
.boardWrite-sendFile-container input {
    display: none;
}
.boardData-send{
    width: 140px;
}