.registerDMP, .registerData, .openDMP{
    background: rgba(0, 0, 0, 0.4); /* 검은 배경, 20% 불투명도 */
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /*gap: 30px;*/
}
.App{
    display: flex;
    flex-direction: column;
    height: 75.9vh;
    overflow: hidden; /* 스크롤 없애기 */
}
.archive-move{
    background: rgba(0, 0, 0, 0.4); /* 검은 배경, 20% 불투명도 */
    padding: 20px 10px;
}
.archive-move a{
    color: white;

}
.registerDMP span, .registerData span, .openDMP span {
    font-weight: bold;
    font-size: 64px;
}

.registerDMP h4, .registerData h4, .openDMP h4 {
    font-weight: bold;
    font-size: 30px;
    margin-top: 24px;
}
.slider-container {
    position: relative;
    height: 60vh; /* 높이를 설정 */
}

.slider-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.slider-image.active {
    opacity: 1;
}

.title-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.img-title {
    display: none; /* 이미지를 배경으로 사용하므로 img-title을 숨깁니다. */
}
.main-title h1, .main-title h2 {
    margin: 0;
    padding: 0;
}
.main-title h1 {
    color: #bb8e38;
    text-align: left;
    font-size: 28px;
    font-weight: 600;
    font-family: "Roboto Light";
}
.main-title h2 {
    color: #f6f6f6;
    font-size: 60px;
    /*line-height: 70px;*/
    font-weight: 400;
    font-family: "Roboto Light";
}
.main-input-box{
    display: flex;
    align-items: center;
    margin-top: 30px;
    position: relative;
}
.main-input-box input {
    width: 800px;
    height: 80px;
    border-bottom: 3px solid #ff4426;
    font-size: 24px;
    padding-left: 14px;
    box-sizing: border-box;
}
input::placeholder {
    font-size: 20px; /* 원하는 크기로 설정 */
    color: #888; /* 선택사항: 색상도 변경할 수 있습니다 */
    vertical-align: center;
}
.main-input-box img {
    position: absolute;
    left: 93%;
    top: 20px;
}

.main-title {
    margin-bottom: 20px; /* main-title과 아래 요소들 사이의 간격 조정 */
}

.mainDataNumber {
    display: flex;
    justify-content: center;
    gap: 10px; /* 요소들 사이의 간격 조정 */
    margin-top: 60px;
}

.mainDataNumber div {
    /*background: rgba(0, 0, 0, 0.6); !* 검은 배경, 20% 불투명도 *!*/
    /*padding: 12px;*/
    color: white;
    border-radius: 10px;
}

.mainDataNumber h2, .mainDataNumber span {
    margin: 0;
    padding: 0;
}
.archive-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

/* 추가적으로 입력 필드와 아이콘을 정렬
input 박스의 스타일을 조정 할 수 있음.
*/

.main-img > div:nth-of-type(3) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px; /* 아래 요소들과의 간격 조정 */
}
.majorServiceIntro{
    padding: 120px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(0deg, #eaebeb, #eaebeb), #3c4a78;
}
.majorService-img{
    display: flex;
    gap: 30px;
    margin-top: 60px;
}
.majorService-img img {
    cursor: pointer;
}
.noticeIntro{
    display: flex;
    justify-content: center;
    gap: 12px;
    text-align:center;
    margin-top: 40px;
    padding: 120px 10px;
}
.notice-contents h1 {
    margin: 0px;
}
.usermanual-img{
    height: 50%;
}

.div_notice{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 40vh;
}
.notice-contents{
    display: flex;
    flex-direction: column;
}

.footer-silder {
    margin: 0 auto;
    max-width: 1280px;
    padding: 14px;
    display: flex;
    gap: 65px;
    align-items: center;
}

.home-archive-title{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
}

.home-archive-title02 img{
    width: 300px;
}

.menual-title{
    height: 100px;
    width: 100px;
}

.home-line {
    border-right: 2px solid black; /* 오른쪽 선 */
    height: 100%; /* 부모 요소에 따라 높이를 조정 */
    margin-right: 10px; /* 오른쪽 여백 추가 (선택 사항) */
    display: inline-block; /* 선이 제대로 표시되도록 블록 설정 */
}


@media screen and (min-width: 1600px) {
    .App {
        height: 86.9vh; /* 해상도가 큰 화면에서는 높이를 더 크게 설정 */
    }
    .slider-container{
        height: 77vh;
    }
}


@media screen and (max-width: 1580px) {
    .registerDMP span, .registerData span, .openDMP span {
        font-weight: bold;
        font-size: 2rem;
    }

    .registerDMP h4, .registerData h4, .openDMP h4 {
        font-weight: bold;
        font-size: 1em;
        margin-top: 1rem;
    }

    .home-archive-title02 img{
        width: 10rem
    }

    .menual-title{
        height: 4rem;
        width: 4rem;
    }

    .home-line {
        border-right: 2px solid black; /* 오른쪽 선 */
        height: 100%; /* 부모 요소에 따라 높이를 조정 */
        margin-right: 10px; /* 오른쪽 여백 추가 (선택 사항) */
        display: inline-block; /* 선이 제대로 표시되도록 블록 설정 */
    }

    .main-title h1{
        font-size: 2rem;
    }
    .main-title h2{
        font-size: 2rem;
    }
    .title-container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .main-input-box input {
        width: 42rem;
        height: 3em;
        border-bottom: 3px solid #ff4426;
        font-size: 24px;
        padding-left: 14px;
        box-sizing: border-box;
    }
    .mainDataNumber{
        margin-top: 1em;
    }
    .home-archive-title{
    margin-top: 1em;
    }

}