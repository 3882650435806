.mycontainer {
    /*height: 100vh;*/
    box-sizing: border-box; /* 패딩을 너비에 포함 */
    display: flex;
    gap: 40px;
    margin-top: 35px;
    padding: 0 40px;
}

.sidebar {
    width: 140px;
    background-color: #f1715d;
    padding: 20px;
    color: white;
    height: 240px;
    border-radius: 10px;
}
.mypage-side_line{
    border: 1px solid black;
    margin: 13px 0;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar li:first-child {
    font-size: 21px;
    font-weight: bold;
}
.sidebar li:last-child {
    font-weight: bold;
}

.sidebar li {
    font-size: 16px;
}

.main-content {
    flex-grow: 1;
    padding: 0 40px;
}
.main-content--title{
    text-align: left;
    margin: 0;
}
.mypage-side_line-title{
    border: 1px solid black;
    margin: 28px 0;
}


.main-content h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.education-table {
    width: 100%;
    border-collapse: collapse;
    margin : 30px 0;
}

.education-table th, .education-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.education-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.pagination button {
    background-color: #ddd;
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination button:disabled {
    cursor: not-allowed;
    background-color: #eee;
}

.pagination span {
    margin: 0 10px;
    font-size: 16px;
}


.menu-title{
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-weight: bold;
    cursor: pointer;
}
.admin-under{
    border-bottom: 1px solid darkgrey;

}


.mypage-calendar{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 800px;
    gap: 15px;
}

.modal-table th,
.modal-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.modal-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.modal-table{
    width: 100%;
    margin-bottom: 20px;
}

.modal-table th {
    text-align: center;
    vertical-align: middle;
}