/* 기본 컨테이너 스타일 */
.file-upload-container {
    position: relative;
    padding: 20px;
}

/* 흐릿한 배경 처리 */
.blur-background {
    filter: blur(5px);
    pointer-events: none; /* 사용자 상호작용 방지 */
}

/* 파일 아이템 스타일 */
.file-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding: 10px;
    background: #f4f4f4;
    border-radius: 5px;
}

.file-item button {
    background: #ff6b6b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* 업로드 버튼 */
.upload-button {
    margin-top: 20px;
    padding: 10px 20px;
    background: #76c7c0;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

/* 업로드 중 모달 */
.upload-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* 모달 내용 */
.modal-content {
    background: #fff;
    /*width: 350px;*/
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}