.dmpReallist-container{
    margin: 0 auto;
    max-width: 1280px;

}.dmpReallist-banner-img{
     position: relative;
 }
.dmpReallist-img02 {
    width: 100%;
    height: 15vh;
}
.banner-Realtext{
    font-weight: 500;
    font-size: 35px;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
}
.banner-Realtext span {
    position: absolute;
    top: 38%;
    left: 40%;
}
.dmpReallist-description{
    position: absolute;
    width: 80%;
    top: 88%;
    background: linear-gradient(90deg, #ff6005 0%, #ff4426 100%);
}
.dmpReallist-description02 {
    display: flex;
    align-items: center;
    padding: 7px;
}
.dmpReallist-description02 span {
    color: #ffffff;
}
/*검색어 css*/

.dmpReallist-inputbox{
    margin: 100px 0;
    position: relative;
}
.dmpReallist-inputbox input {
    box-sizing: border-box;
    width: 500px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    padding-left: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #444444;
}
.dmpReallist-inputbox img {
    position: absolute;
    left: 16px;
    top: 19px;
    width: 24px;

}
.btn-Realcontainer{
    display: flex;
    justify-content: space-between;
}
.btn-Realcheck{
    display: flex;
    gap: 20px;
}
.btn-Realcheck .Realcreate02-dmp {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    background-color: #ff5d43;
    text-decoration: none;
    color: white;

}
.btn-Realcheck .Realsend02-dmp {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ff5d43;
    background-color: white;

}
.btn-Realcheck .Realsend02-dmp a {
    text-decoration: none;
    color: #ff5d43;
}

.dmpReallist-start_line{
    border: 1px solid black;
    margin: 30px 0;
}


.dmpReallist-title th{
    padding: 20px 0;
}
.dmpReallist-start_liness{
    margin: 20px 0;
}
.dmpReallist-Data{
    padding: 14px 0;
    height: 60px;
    font-size: 15px;
    font-weight: 500;
    line-height: 32px;
    color: #101010;
    text-align: center;
    border-top: 1px solid #f2f2f2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.update-page{
    cursor: pointer;
    text-align: left;
}
.boards-page{
    cursor: pointer;
}

.dmpSeminarData{
    margin-top: 100px;
    display: flex;
    gap: 50px;
    justify-content: center;
}
.dmpSeminarData button {
    padding: 14px 40px;
    border-radius: 20px;
}

/**/

.dmpSeminarData {
    display: flex;
    gap: 20px;
}

.dmpSeminarData button {
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background-color: #f5f5f5;
    color: #000;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dmpSeminarData button.active {
    background-color: #d9412c;
    color: white;
}

.dmpSeminarData button:hover {
    background-color: #e0e0e0;
}

.dmpSeminarData button.active:hover {
    background-color: #b7311e;
}
.data-hisbtn{
    cursor: pointer;
}


/*공개 데이터 css 양식*/
.btn-modalOpenData{
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #ff5d43;
    background-color: white;
}
.btn-oath{
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    background-color: #ff5d43;
    text-decoration: none;
    cursor: pointer;
    color: white;
}

/*이력 버튼 css*/
.data-hisbtn{
    margin-left: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    background-color: #bdbdbd;
    text-decoration: none;
    color: white;
}

/*board에 해당 하는 css*/


/*데이터 큐레이션 css*/
.curation-title{
    display: flex;
    justify-content: center; /* 수평 가운데 정렬 */
    align-items: center; /* 수직 가운데 정렬 */
    height: 100vh; /* 부모 높이 설정 */

}